/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label,global-require */
import React, { useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import ModalVideo from 'react-modal-video';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
// import useHtmlFragment from '../utils/useHtmlFragment';
import SEO from '../components/seo/SEO';
import Hero from '../components/Hero';
import TimeshareVideo from '../components/images/TimeshareVideo';
import StopPropagation from '../components/StopPropagation';
import BBB from '../components/images/BBB';
import FeaturedTestimonial from '../components/FeaturedTestimonial';
import ContactButton from '../components/ContactButton';
import useI18n from '../utils/useI18n';
import LinkedInProfiles from '../components/LinkedInProfiles';
import '../styles/components/_components.videoAboutButton.scss';

const isBrowser = typeof window !== 'undefined';

const About = ({ pageContext: { switchLang }, location, data }) => {
  const { t } = useI18n();
  const [visible, setVisible] = useState(false);
  const AboutContent = data.about.html;
  const handleOpen = () => {
    setVisible(true);
    if (window.gtag) {
      window.gtag('event', 'reproducir', {
        event_category: 'videos',
        event_label: 'timeshare cancellation testimonial',
        value: 1,
      });
    }
  };
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('about.meta.title')}
        description={t('about.meta.description')}
        keywords={t('about.meta.keywords')}
        pathname={location.pathname}
        organization
      />
      <Hero secondary title={t('about.hero.title')} callout={t('about.hero.callout')} />
      <div className="o-wrapper u-margin-top-large u-margin-bottom">
        <div className="o-layout">
          <div className="o-layout__item u-8/12@desktop">
            <div dangerouslySetInnerHTML={{ __html: AboutContent }} />
            <h3>LinkedIn Profiles</h3>
            <LinkedInProfiles />
          </div>
          <StopPropagation preventDefault className="o-layout__item u-4/12@desktop u-align-center">
            <a
              className="c-videoAboutButton noGatsbyLink"
              href="https://www.youtube.com/watch?v=rcZq6VE1ywo"
              onClick={handleOpen}
            >
              <TimeshareVideo />
              <span className="c-videoAboutButton__icon">
                <CaretRightOutlined />
              </span>
            </a>
            <span className="u-h4 u-margin-bottom">{t('about.video.title')}</span>
            {/*<a
              href="https://www.bbb.org/us/ca/hayward/profile/timeshare-advocates/mexican-timeshare-solutions-1116-383105"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <BBB />
            </a>*/}
          </StopPropagation>
          {isBrowser && (
            <ModalVideo
              channel="youtube"
              isOpen={visible}
              videoId="8nBAVe9qrwY"
              onClose={() => setVisible(false)}
            />
          )}
        </div>
      </div>
      <FeaturedTestimonial />
      <ContactButton section />
    </Layout>
  );
};

export const query = graphql`
  query AboutPageQuery {
    about: htmlFragment(name: { eq: "about" }) {
      html
    }
  }
`;

export default About;
