import React from 'react';

import useI18n from '../utils/useI18n';
import Valentina from './images/Valentina';
import LindaMolina from './images/LindaMolina';
import MTSLinkedIn from './images/MTSLinkedIn';
import RaulMorales from './images/RaulMorales';

import '../styles/components/_components.linkedInProfiles.scss';

const LinkedInProfiles = () => {
  const { t } = useI18n();
  return (
    <ul className="c-linkedInProfiles o-layout o-layout--flush">
      <li className="o-layout__item u-6/12@tablet">
        <div className="c-linkedInProfiles__item">
          <div className="c-linkedInProfiles__img">
            <Valentina />
          </div>
          <div className="c-linkedInProfiles__itemText">
            <a
              className="u-h5"
              href="https://www.linkedin.com/in/valentina-z-ricci-6976841ab/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Valentina Z. Ricci
            </a>
            <span>{t('about.profile.valentinaRicci')}</span>
            <a
              href="https://www.linkedin.com/in/valentina-z-ricci-6976841ab/"
              className="c-blockLink"
              target="_blank"
              rel="noreferrer nofollow"
            />
          </div>
        </div>
      </li>
      <li className="o-layout__item u-6/12@tablet">
        <div className="c-linkedInProfiles__item">
          <div className="c-linkedInProfiles__img">
            <RaulMorales />
          </div>
          <div className="c-linkedInProfiles__itemText">
            <a
              className="u-h5"
              href="https://www.linkedin.com/in/raul-morales-a2383b1b5/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Raúl Morales
            </a>
            <span>{t('about.profile.raulMorales')}</span>
            <a
              href="https://www.linkedin.com/in/raul-morales-a2383b1b5/"
              className="c-blockLink"
              target="_blank"
              rel="noreferrer nofollow"
            />
          </div>
        </div>
      </li>
      <li className="o-layout__item u-6/12@tablet">
        <div className="c-linkedInProfiles__item">
          <div className="c-linkedInProfiles__img">
            <MTSLinkedIn />
          </div>
          <div className="c-linkedInProfiles__itemText">
            <a
              className="u-h5"
              href="https://www.linkedin.com/company/mexicantimesharesolutions/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Mexican Timeshare Solutions
            </a>
            <span>{t('about.profile.mts')}</span>
            <a
              href="https://www.linkedin.com/company/mexicantimesharesolutions/"
              className="c-blockLink"
              target="_blank"
              rel="noreferrer nofollow"
            />
          </div>
        </div>
      </li>
      <li className="o-layout__item u-6/12@tablet">
        <div className="c-linkedInProfiles__item">
          <div className="c-linkedInProfiles__img">
            <LindaMolina />
          </div>
          <div className="c-linkedInProfiles__itemText">
            <a
              className="u-h5"
              href="https://www.linkedin.com/in/linda-molina-87811174/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Linda Molina
            </a>
            <span>{t('about.profile.lindaMolina')}</span>
            <a
              href="https://www.linkedin.com/in/linda-molina-87811174/"
              className="c-blockLink"
              target="_blank"
              rel="noreferrer nofollow"
            />
          </div>
        </div>
      </li>
    </ul>
  );
};

export default LinkedInProfiles;
